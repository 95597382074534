$white: #ffffff;
$black: #000000;

$primary: #e9155f;
$primary-light: #fde7ef;

$grey: #445266;
$light-grey: #f6f6f8;
$light-grey2: #ededf1;
$dark-grey: #333e4d;
$dark-grey2: #333;

$light-blue: #b3d4fc;
