@import "Palette.scss";

@mixin font-face($style-name, $file, $weight) {
    $filepath: "/assets/fonts/" + $file;
    @font-face {
        font-family: quote($style-name);
        src: url($filepath + ".eot");
        src: url($filepath + ".eot?#iefix") format('embedded-opentype'),
        url($filepath + ".woff") format('woff'),
        url($filepath + ".ttf") format('truetype'),
        url($filepath + ".svg#" + $style-name + "") format('svg');
        font-weight: $weight;
        font-style: normal;
    }
}

@include font-face('Montserrat', 'Montserrat-Regular', 400);
@include font-face('Montserrat', 'Montserrat-Light', 300);
@include font-face('Montserrat', 'Montserrat-Bold', 700);

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
}

:focus {
    outline: 0;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
    font-family: 'Montserrat', sans-serif;
    margin: 0 auto;
    padding: 0;
    font-weight: 400;
    color: $grey;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type=search] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
}

* {
    box-sizing: border-box;
}

[hidden] {
    display: none;
}

html {
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

a:focus {
    outline: thin dotted;
}

a:active,
a:hover {
    outline: 0;
}
img {
    border: 0;
    -ms-interpolation-mode: bicubic;
}

figure {
    margin: 0;
}

form {
    margin: 0;
}

fieldset {
    border: 1px solid $light-grey;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

legend {
    border: 0;
    padding: 0;
    white-space: normal;
    *margin-left: -7px;
}

button,
input,
select,
textarea {
    font-size: 100%;
    margin: 0;
    vertical-align: baseline;
    *vertical-align: middle;
}

button,
input {
    line-height: normal;
}

button,
select {
    text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
    *overflow: visible;
}

button[disabled],
html input[disabled],
textarea[disabled] {
    cursor: not-allowed;
    background-color: #ededf1!important;
}

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
    *height: 13px;
    *width: 13px;
}


input[type="search"] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

textarea {
    overflow: auto;
    vertical-align: top;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

::-moz-selection {
    background: $light-blue;
    text-shadow: none;
}

::selection {
    background: $light-blue;
    text-shadow: none;
}

img {
    vertical-align: middle;
    height: inherit;
    width: inherit;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

h1 {
    font-size: 24px;
    line-height: 1.5;
}

h2 {
    color: $grey;
    font-size: 3rem;
    line-height: 1.55;
    text-align: center;
    margin-bottom: 70px;
    font-family: 'Georgia', serif;
}

b {
    font-weight: 700;
}

.switcher {
    position: absolute;
    top: 1rem;
    right: 1rem;

    & select {
        font-family: Montserrat, sans-serif;
        color: #333;
        border: 1px solid #445266;
        border-radius: 7px;
        font-size: 16px;
        height: 35px;
        width: fit-content;
        padding: 0 1.25rem;
        background-color: #ffffff;
        line-height: 1;
        font-weight: 500;
        cursor: pointer;
    }
}

header {
    padding: 1.5rem 0 4rem;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;

    &._flex {
        display: flex;
        justify-content: space-between;
    }

    &._center {
        align-items: center;
    }
}

@media(max-width: 1500px) {
    .container {
        max-width: 1140px;
    }
    .main_sec .container {
        max-width: 1140px;
    }
    header .container {
        max-width: 1140px;
    }
}

@media(max-width: 1199px) {
    .container {
        max-width: 970px;
    }
    .main_sec .container {
        max-width: 970px;
    }
    .main_sec-wrapper {
        max-width: 970px;
        margin: auto;
    }
    header .container {
        max-width: 970px;
    }
}

@media(max-width: 991px) {
    .container {
        max-width: 740px;
    }
    .main_sec .container {
        max-width: 740px;
    }
    header .container {
        max-width: 740px;
    }
}

@media(max-width: 767px) {
    .container {
        max-width: 100%;

        &._flex {
            flex-direction: column;

            & .btn_block {
                margin: 0 auto;
            }
        }
    }
    .main_sec .container {
        max-width: 100%;
    }
    header .container {
        max-width: 100%;
    }
}

.logo {
    max-width: 17rem;
    display: block;
    width: 100%;
    height: auto;
}

.cover_outside {
    display: flex;
    justify-content: space-between;
}

.btn {
    font-size: 1.25rem;
    color: $white;
    line-height: 1.55;
    font-weight: 600;
    border-radius: 10px;
    background-color: $primary;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    box-shadow: 0 0 10px 0 rgba(84, 99, 119, 0.7);
    text-decoration: none;
    width: 300px;
    padding: 1.25rem 0 1.25rem;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;

    &_block {
        text-align: center;
        display: flex;
    }

    &:hover {
        background-color: darken($primary, 5);
        cursor: pointer;
    }
}

.main_sec {
    position: relative;

    &-wrapper {
        height: fit-content;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    height: 100vh;

    &::before {
        z-index: -1;
        position: absolute;
        display: inline-block;
        width: 100%;
        height: 100vh;
        left: 0;
        top: 0;
        opacity: 0.3;
        content: "";
        background: url(https://demoaxilink.axiomatika.ru/assets/images/bg.jpg) no-repeat 0 0;
        background-size: cover;
    }

    & .container {
        max-width: 1200px;
    }

    & .left {
        width: 50%;
        max-width: 610px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        & span {
            font-size: 1.25rem;
            line-height: 1.5;
            font-weight: 300;
            margin-bottom: 4rem;
            display: inline-block;
        }
    }

    & .right {
        width: fit-content;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;

        & svg {
            max-width: 90%;
            height: auto;
            position: relative;
            animation-name: rotate-windows;
            animation-iteration-count: infinite;
            animation-duration: 3s;
            animation-fill-mode: forwards;
        }
    }

    & .name {
        color: $grey;
        font-size: 3rem;
        line-height: 1.35;
        font-weight: 700;
        margin-bottom: 1rem;
        font-family: 'Georgia', serif;
    }
}

b {
    font-weight: 700;
}

.what_is {
    background-color: $light-grey;
    padding: 4rem 0;

    & h2 {
        color: $grey;
    }

    & p {
        font-size: 1.25rem;
        line-height: 1.55;
        font-weight: 300;
        text-align: justify;

        &.bold {
            font-weight: 500;
            margin-top: 2.5rem;
            text-align: justify;
        }
    }

    & .btn {
        margin: 4rem auto 0;
        padding: 1rem 0;
        width: 18rem;

        &_block {
            text-align: center;
        }
    }
}

.decision {
    padding-bottom: 5rem;

    & .undertext {
        margin-bottom: 3rem;
    }

    & .top {
        margin-bottom: 5rem;
        margin-top: 8rem;
        font-size: 1.625rem;
        text-align: center;
    }

    & .info_block {
        color: $grey;
        font-size: 1.25rem;
        line-height: 1.55;
        font-weight: 300;
        max-width: 25ch;
    }

    & .cover_outside {
        align-items: flex-start;
        gap: 6rem;

        & .info_block {
            &:last-of-type {
                margin: 0 auto;
            }
        }
    }

    &__functionality {
        position: relative;
        ul {
            text-align: justify;
            line-height: 1.5rem;
            margin-top: 3rem;
            font-weight: 500;

            @media screen and (max-width: 767px) {
                margin-bottom: 5rem;
            }
        }
        ul.dashed {
            list-style-type: none;

            &:before {
                position: absolute;
                left: 50%;
                width: 250px;
                top: 60px;
                height: 125px;
                border-radius: 10rem 10rem 0 0;
                content: "";
                transform: translate(-50%, 90px);
                background-color: rgba(233, 21, 95, 0.1);

                @media screen and (max-width: 610px) {
                    transform: translate(-50%, 180px);
                }
            }
        }
        ul.dashed > li {
            text-indent: -5px;
        }
        ul.dashed > li:before {
            content: "-";
            text-indent: -5px;
            margin-right: 5px;
        }
    }

    & h2 {
        color: $grey;
        margin: 10rem 0;
        position: relative;

        &:before {
            position: absolute;
            left: 50%;
            top: 50%;
            content: "";
            background-color: rgba(233, 21, 95, 0.1);
            width: 20.25rem;
            height: 20.25rem;
            transform: translate(-50%, -50%);
            border-radius: 50%;
        }
    }

    & .bott {
        color: $grey;
        font-size: 1.25rem;
        font-weight: 300;
        line-height: 1.55;
        text-align: justify;
    }
}

.form {
    overflow: hidden;
    position: relative;
    padding-bottom: 50px;
    background-color: $light-grey;
    padding-top: 100px;
    font-size: 1.25rem;
    line-height: 1.55;

    &-theme {
        display: none;
    }

    &__text {
        &_error-message {
            display: inherit;

            font-size: 0.8rem;
            font-weight: 600;

            color: #c52d62c7;
        }
    }
}

.form:before {
    position: absolute;
    left: 50%;
    width: 670px;
    top: 60px;
    height: 670px;
    border-radius: 50%;
    content: "";
    transform: translate(-50%, 0%);
    background-color: rgba(233, 21, 95, 0.1);
}

.form .text_block {
    width: 50%;
    font-size: 26px;
    text-align: justify;
    padding-top: 1rem;

    & b {
        font-family: 'Georgia', serif;
        font-size: 1.6rem;
    }
}

form {
    position: relative;
    z-index: 2;
}

form input, form textarea {
    color: $dark-grey2;
    border: 1px solid $grey;
    border-radius: 7px;
    font-size: 16px;
    font-weight: 500;
    height: 45px;
    line-height: 1.33;
    width: 100%;
    padding: 0 1.25rem;
    background-color: $white;
}

form label {
    font-size: 18px;
    color: $dark-grey2;
    margin-bottom: 5px;
}

form li {
    margin-bottom: 12px;
}

form input[type="submit"] {
    color: $white;
    background-color: $primary;
    border-radius: 7px;
    font-size: 18px;
    width: 160px;
    height: 50px;
    padding: 0 15px;
    border: none;
    text-transform: uppercase;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    margin-top: 15px;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;

    &:hover {
        background-color: darken($primary, 5);
    }
}

form textarea {
    height: 96px;
    resize: none;
}

footer {
    background-color: $dark-grey;
    padding-top: 70px;
    padding-bottom: 70px;

    & .social {
        margin-bottom: 1.25rem;
        display: flex;
        justify-content: space-between;

        & li a img:hover {
                filter: invert(70%) sepia(87%) saturate(6096%) hue-rotate(330deg) brightness(94%) contrast(94%);
        }
    }

    & .menu_block {
        & a {
            color: $white;
            font-size: 14px;
            font-weight: 400;
            text-transform: none;
            text-decoration: none;
        }

        & li {
            color: $white;
            margin-bottom: 15px;
            font-weight: 700;
            font-size: 16px;
            text-transform: uppercase;

            & a {
                transition: all .2s ease-out;
                &:hover {
                    color: $primary;
                    transition: all .2s ease-out;
                }
            }

            & span {
                color: $primary;
            }
        }
    }

    & .logo {
        margin-bottom: 1.25rem;
    }
}

.list-wrapper {
    width: 100%;
}
.list__item {
    width: 100%;
    color: $grey;
    padding: 1rem 0;
    border-bottom: 1px solid $light-grey;

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }

    &-title {
        display: flex;
        justify-content: space-between;
        padding-bottom: 0.5rem;

        & span:first-child {
            color: $primary !important;
            opacity: .7;
            max-width: 70%;
        }

        & span:not(:first-child) {
            opacity: .4;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 20ch;
            white-space: nowrap;

            &:hover {
                cursor: pointer;
            }

            &.all {
                width: fit-content;
                white-space: break-spaces;
                text-align: justify;
            }
        }
    }
}


@media screen and (max-width: 1500px) {
    .container {
        padding: 0 1.5rem;
    }

    .btn {
        width: 200px !important;
        font-size: 1rem;
        padding: 0.8rem;
    }

    .wrapper {
        position: relative;
        overflow: hidden;
    }

    h2 {
        margin-bottom: 28px;
    }

    .main_sec {
        & .name {
            font-size: 2rem;
        }

        & .left {
            width: 100%;

            & span {
                margin-bottom: 45px;
            }
        }

        & .right img {
            height: 80%;
            width: 80%;
        }
    }

    .what_is .btn {
        margin-top: 30px;
    }

    .decision {
        padding-bottom: 100px;

        & .top {
            margin-bottom: 55px;
            margin-top: 8rem;
        }

        & h2 {
            margin-top: 100px;
        }
    }

    .form {
        padding-top: 80px;
    }
}

@media screen and (max-width: 1199px) {
    .form .text_block {
        font-size: 1.25rem;
        text-align: center;
    }

    .what_is {
        padding-top: 50px;
    }

    .decision {
        padding-bottom: 50px;

        & .info_block {
            width: 40%;
            max-width: 100%;
        }

        & .cover_outside {
            flex-wrap: wrap;
            gap: 3rem;
        }

        & .top {
            margin-bottom: 50px;
            margin-top: 8rem;
        }

        & .info_block {
            margin-bottom: 30px;
        }

        & h2 {
            margin-top: 50px;

            &:before {
                width: 150px;
                height: 150px;
            }
        }
    }

    .main_sec {
        margin-bottom: 100px;

        & .right svg {
            max-width: 58%;
        }
    }

    h2 {
        font-size: 36px;
    }

    .list h2 {
        margin-bottom: 30px;
    }
    .form {
        padding-top: 50px;

        & .cover_outside {
            flex-direction: column;
        }

        & .text_block {
            width: 100%;
            padding-top: 0;
            margin-bottom: 30px;
        }

        & .form_block {
            width: 400px;
            max-width: 100%;
            margin-right: auto;
            margin-left: auto;
        }

        & li:last-child {
            text-align: center;
        }
    }

    .name {
        font-size: 2rem;
    }
}

.doc {
    &-title {
        font-size: 1.5rem;
        font-weight: 500;
        color: #554c66;
        margin-bottom: 1.5rem;
    }

    &-list {
        margin-bottom: 4rem;
    }

    &-link {
        margin-bottom: .5rem;

        & a {
            text-decoration: none;
            color: #2196F3;
        }
    }
}

.support {
    &-title {
        font-size: 1.5rem;
        font-weight: 500;
        color: #554c66;
        margin-bottom: 1.5rem;
    }

    &-list {
        margin-bottom: 3rem;
    }

    &__link {
        margin-bottom: .5rem;

        &__label {
            font-weight: 500;
        }
        &__content {
            margin-left: .5rem;
        }
        & a {
            text-decoration: none;
            color: #2196F3;
        }
    }
}

.demo {
    padding: 0 0 5rem;
    &-prices {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        &__order span {
            color: $primary;
            font-size: 1.6rem;
        }

        &__desc span {
            line-height: 1.5rem;
            font-size: 1.2rem;
        }

        &__title {
            font-size: 1.6rem;
            margin-bottom: 1rem;
        }
    }
}
@media screen and (max-width: 767px) {

    .main_sec {
        min-height: -webkit-fill-available;
        max-height: -webkit-fill-available;

        &::before {
            min-height: -webkit-fill-available;
            max-height: -webkit-fill-available;
        }

        & .btn_block {
            justify-content: center;
        }
    }
    .nav {
        &-list {
            font-size: 14px;
        }

        &-item {
            & .section{
                &-title {
                    padding: 1rem !important;
                }
            }

            &.active .section-content {
                padding: 1rem !important;
            }
        }
    }

    .main_sec {
        margin-bottom: 50px;

        & .right {
            display: none;
        }

        & .cover_outside {
            flex-direction: column-reverse;
        }

        &.left,
        & .right {
            width: 100%;
        }

        & .right {
            margin-top: 1.25rem;
        }

        & .left {
            margin-top: 30px;
            text-align: center;

            & span {
                font-size: 16px;
                margin-bottom: 30px;
            }
        }

        & h1 {
            font-size: 1.25rem;
        }
    }
    .name {
        font-size: 2rem;
        margin-top: 12px;
    }

    .logo {
        margin-right: auto;
        margin-left: auto;
    }

    h2 {
        font-size: 24px;
        margin-bottom: 30px;
    }

    .what_is {
        padding-top: 30px;
        padding-bottom: 30px;

        & p {
            font-size: 16px;
        }

        & .btn {
            margin-top: 30px;
        }
    }

    .decision {
        padding-bottom: 30px;
    }
    .decision .top {
        font-size: 1.25rem;
    }
    .decision .info_block {
        width: 100%;
        font-size: 16px;
    }
    .decision .bott {
        font-size: 16px;
    }
    .decision h2 {
        margin-bottom: 30px;
    }
    .list {
        padding: 30px 0;
    }
    .list h2 {
        font-size: 1.25rem;
    }
    .form {
        & .text_block {
            font-size: 1rem;

            & b {
                font-size: 1rem;
            }
        }

        & label {
            font-size: 1rem;
        }

        & input[type=submit] {
            font-size: 1rem;
        }
    }

    .form,
    footer {
        padding: 30px 0;
    }
    footer .cover_outside {
        flex-direction: column;
        padding-bottom: 30px;
        position: relative;
    }
    footer .copyright {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
    }
    footer .menu_block {
        width: 80%;
        margin: 15px auto;
    }
    footer .social {
        justify-content: space-around;
        margin-top: 30px;
    }

    .demo-prices {
        align-items: center;
        margin-bottom: 2rem;
    }
}
